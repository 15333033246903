import React from 'react';
import '../css/setting.css';
import LeftMenu from '../components/common/LeftMenu';
import Header from '../components/common/Header';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from "axios"
import UserPreferences from "../components/settings/UserPreferences"
import { toast } from "react-hot-toast"
import { toastError, toastSuccess } from '../components/common/toastUtils';

export default function Settings() {
  const [passwordShown, setPasswordShown] = useState(false);
  const [twoCaptchaKey, setTwoCaptchaKey] = useState("");
  const [capsolverCaptchaKey, setCapsolverCaptchaKey] = useState("");
  const [displayedKey, setDisplayedKey] = useState(twoCaptchaKey);
  const [captchaType, setCaptchaType] = useState(null);
  const [captchaBalance, setCaptchaBalance] = useState("")

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const headers = {
    'ngrok-skip-browser-warning': 'skip',
    'bypass-tunnel-reminder': 'skip',
    'x-user-provided-key': UserPreferences.getServerPassword(),
    'Content-type': 'application/json'
  }

  useEffect(() => {
    getSettings()
    // eslint-disable-next-line
  }, [])

  function getSettings() {
    axios
      .get(UserPreferences.getServerUrl() + "/settings", { headers: headers })
      .then((res) => {
        setCapsolverCaptchaKey(res.data.settings.capsolver)
        setTwoCaptchaKey(res.data.settings.twocaptcha)
      })
      .catch((e) => {
        toastError(e)
        return console.log(`fetching issue due to ${e}`);
      });
  }

  function patchCaptcha(data) {
    axios
      .put(UserPreferences.getServerUrl() + "/settings", data, { headers: headers })
      .then((res) => {
        console.log(res.data);
        toastSuccess("Saved")
      })
      .catch((e) => {
        toastError(e)
        return console.log(`fetching issue due to ${e}`);
      });
  }
  const [serverPassword, setServerPassword] = useState(
    UserPreferences.getServerPassword() || ""
  );

  function getBalance(captchaprovider) {
    axios
      .get(UserPreferences.getServerUrl() + "/captchas/" + captchaprovider + "/balance", { headers: headers })
      .then((res) => {
        if (res.data.balance === "ERROR_WRONG_USER_KEY" || res.data.balance === "ERROR_KEY_DOES_NOT_EXIST"){
          setCaptchaBalance("Error")
        }
        else{
          setCaptchaBalance(parseFloat(res.data.balance).toFixed(2) + " $")
        }
      })
      .catch((e) => {
        return console.log(`fetching issue due to ${e}`);
      });
  }


  const handleCaptchaTypeChange = (event) => {
    setCaptchaType(event.target.value)
    setCaptchaBalance("")
    if (event.target.value === "capsolver") {
      setDisplayedKey(capsolverCaptchaKey)
      setCaptchaType("capsolver")

    }
    if (event.target.value === "2captcha") {
      setDisplayedKey(twoCaptchaKey)
      setCaptchaType("2captcha")
    }
  };

  const handleCaptchaChange = (event) => {
    setDisplayedKey(event.target.value)
    if (captchaType === "capsolver") {
      setCapsolverCaptchaKey(event.target.value)
    }
    if (captchaType === "2captcha") {
      setTwoCaptchaKey(event.target.value)
    }
  };


  const handleCaptchaClick = () => {
    var data = {
      "capsolver": capsolverCaptchaKey,
      "twocaptcha": twoCaptchaKey
    }
    patchCaptcha(data)
  };

  const handleServerPasswordChange = (event) => {
    setServerPassword(event.target.value);
  };

  const handleServerPasswordClick = () => {
    localStorage.setItem('server.pwd', serverPassword);
    toastSuccess("Saved")
  };

  return (
    <div className="profile">
      <div className="main-container">
        <LeftMenu />
        <div className="right-side">
          <Header />

          <div className="middle-section-container flex">
            <div className="settings-section">
              <div className="item1 flex">
                <div className="user-info">
                  <h2 className="font-size-17 font-weight-600 white">Settings</h2>
                </div>
              </div>

              <div className="setting_container">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 padding-left-0">
                      <div className="profiles-sections bg-settings">
                        <div className="border_2 border-radius-5 padding-20">
                          <h3 className="white font-size-15 font-weight-600">Application </h3>
                          <p> Manage application settings </p>
                          <div className="form-group">
                            <label> AIO server URL </label>
                            <div className="widthtest">
                              <input type="text" disabled value={UserPreferences?.getServerUrl()} className="setting-input-locked" />
                            </div>
                          </div>
                          <div className="form-group">
                            <label> Password Server </label>
                            <p> Define this password to interact securely with your server. </p>
                            <div className="widthtest">
                              <input id="password"  type={passwordShown ? "text" : "password"}  className="setting-input pass" onChange={handleServerPasswordChange} value={serverPassword}/>
                              <div className="show" onClick={togglePasswordVisiblity}><img alt="" src={require('../assets/eye.png')} />   </div>
                            </div>
                          </div>
                          <div className="sava-btn"> <button onClick={handleServerPasswordClick}>Save Settings</button> </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 padding-left-0">
                      <div className="profiles-sections bg-settings">
                        <div className="border_2 border-radius-5 padding-20">
                          <h3 className="white font-size-15 font-weight-600">Captcha </h3>
                          <p>Manage captcha settings </p>
                          <div className="form-group">
                            <label>Captcha Provider </label>
                            <div className="widthtest setting_select">
                              <select onChange={(e) => handleCaptchaTypeChange(e)}>
                                <option value="select"> Select a provider</option>
                                <option value="2captcha"> 2captcha</option>
                                <option value="capsolver">capsolver</option>

                              </select>
                            </div>
                          </div>
                          {captchaType !== null &&
                            <>
                              <div className="form-group">
                                <label> 2captcha API Key </label>
                                <div className="widthtest">
                                  <input type="text" value={displayedKey} className="setting-input pass" onChange={handleCaptchaChange} />
                                </div>
                              </div>
                              { captchaBalance != "" && (
                                  <label style={ captchaBalance !== "Error" ? {color:"#62C06B"}:{color:"#FF7272"}}>Balance: {captchaBalance} </label>
                              )}
                              <div className="flex mag_1">
                                <div className="sava-btn"><button onClick={handleCaptchaClick}>Save Captcha Settings</button> </div>
                                <div className="sava-btn test_api"><button className="gren" onClick={() => getBalance(captchaType)}>Get Balance ($) <img alt="" src={require('../assets/play.png')} /> </button></div>
                              </div>
                            </>


}
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
